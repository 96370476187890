<template>
  <div>
    <div class="sm:flex sm:items-center sm:justify-between mb-20 px-16 sm:px-0">
      <h1 class="font-medium text-lg sm:mb-0 whitespace-no-wrap">Ordreliste</h1>

      <div class="flex flex-wrap sm:ml-16">
        <el-button
          tag="button"
          :disabled="!getOrdersList.length"
          :loading="buttonLoading"
          class="group mr-16 mt-12"
          @click="onDownloadOrdersFile('excel')"
        >
          <template #icon>
            <span
              class="icon-download text-grey-500 text-xl block"
              :class="{ 'group-hover:text-grey-400': getOrdersList.length }"
            />
          </template>
          Hent Excel fil
        </el-button>

        <el-button
          tag="button"
          :disabled="!getOrdersList.length"
          :loading="buttonLoading"
          class="group mr-16 mt-12"
          @click="onDownloadOrdersFile('csv')"
        >
          <template #icon>
            <span
              class="icon-download text-grey-500 text-xl block"
              :class="{ 'group-hover:text-grey-400': getOrdersList.length }"
            />
          </template>
          Hent CSV fil
        </el-button>

        <el-button
          tag="button"
          type="primary"
          :disabled="!getMarkedOrdersLength"
          :loading="buttonLoading"
          class="mt-12"
          @click="onFulfillOrder"
        >
          {{ orderButtonLabel }}
        </el-button>
      </div>
    </div>

    <div class="sm:flex sm:items-center sm:justify-end mb-20 px-16 sm:px-0">
      <div class="flex flex-wrap sm:ml-16">
        <div class="flex sm:items-center sm:justify-between">
          <el-button
            tag="button"
            type="primary"
            :loading="buttonLoading"
            class="mr-12"
            @click="onSearchOrders"
          >
            Søg
          </el-button>
          Start dato: <el-date-picker v-model="startDate" data-date-type="startDate" class="max-w-lg shadow-sm sm:max-w-xs" @dayclick="onSetDate($event, 'startDate')" />
          Slut dato: <el-date-picker v-model="endDate" data-date-type="endDate" :value="endDate" class="max-w-lg shadow-sm sm:max-w-xs" @dayclick="onSetDate($event, 'endDate')" />
        </div>
      </div>
    </div>

    <div class="flex flex-col sm:flex-row sm:items-center justify-between mb-20">
      <el-checkbox v-model="selectAll" class="ml-16 sm:ml-24" @input="onMarkAllOrders">Vælg all åbne ordrer</el-checkbox>
      <el-checkbox v-model="showAllOrders" class="ml-16 sm:ml-0 mt-20 sm:mt-20 mr-16 sm:ml-24" @input="onSearchOrders">Vis alle ordrer</el-checkbox>
    </div>

    <orders-list :orders-list="getOrdersList" :is-row-click="!getMarkedOrdersLength" />

    <div class="mt-24">
      <div class="flex items-center">
        <el-status status="open" />
        <p class="ml-4">Ordre er åben og ingen linier afsendt</p>
      </div>
      <div class="flex items-center mt-2">
        <el-status status="sent" />
        <p class="ml-4">Ordre afsluttet</p>
      </div>
    </div>
  </div>
</template>

<script>
import OrdersService from '@/services/orders.service'
import { mapActions, mapState } from 'vuex'
import { enums } from '@/core/enums'
import moment from 'moment'

const ElButton = () => import('@/components/shared/ELButton')
const ElStatus = () => import('@/components/shared/ELStatus')
const ElCheckbox = () => import('@/components/shared/ELCheckbox')
const ElDatePicker = () => import('@/components/shared/ELDatePicker')
const OrdersList = () => import('@/components/orders/OrdersList')

export default {
  name: 'OrdersPage',

  components: { ElButton, ElStatus, ElCheckbox, OrdersList, ElDatePicker },

  data () {
    return {
      ordersList: [],
      selectAll: false,
      showAllOrders: false,
      buttonLoading: false,
      startDate: null,
      endDate: null
    }
  },

  computed: {

    isAdmin () {
      console.log('IsAdmin', this.userData, this.userData && (this.userData.role === enums.ROLES.ADMIN))
      return this.userData && (this.userData.role === enums.ROLES.ADMIN)
    },

    ...mapState({
      userData: state => state.user.userData
    }),

    getMarkedOrdersLength () {
      return this.getOrdersList.filter(order => order.isMarked).length
    },

    getMarkedOrders () {
      return this.getOrdersList.filter(order => order.isMarked)
    },

    getOrdersList () {
      return this.ordersList
    },

    orderButtonLabel () {
      const isMarkedOrders = this.getMarkedOrdersLength
      if (isMarkedOrders > 0) return `Fulfill selected orders (${isMarkedOrders})`
      return 'Fulfill selected orders'
    }
  },

  created () {
    this.firstLoad()
  },

  methods: {
    ...mapActions(['loading', 'showNotification']),

    onSetDate (day, dateType) {
      if (dateType === 'startDate') {
        this.startDate = day.date
      } else {
        this.endDate = day.date
      }
    },

    getDateFromDays (days) {
      if (!days && days !== 0) {
        days = 14
      }
      return moment().add(days, 'days').toDate()
    },

    firstLoad () {
      if (this.isAdmin) {
        this.startDate = this.getDateFromDays(-14)
        this.endDate = this.getDateFromDays(0)
      }
      this.searchOrders()
    },

    searchOrders () {
      this.loading(true)
      const startDate = this.startDate ? moment(this.startDate).format('YYYY-MM-DD') : null
      const endDate = this.endDate ? moment(this.endDate).format('YYYY-MM-DD') : null
      return OrdersService.getOrdersList(this.showAllOrders, startDate, endDate)
        .then(res => {
          this.ordersList = res.map(order => {
            order.isMarked = false
            return order
          })
          this.loading(false)
        })
    },

    onSearchOrders () {
      this.searchOrders()
    },

    onDownloadOrdersFile (fileType) {
      const startDate = this.startDate ? moment(this.startDate).format('YYYY-MM-DD') : null
      const endDate = this.endDate ? moment(this.endDate).format('YYYY-MM-DD') : null
      OrdersService.downloadOrdersFile({ format: fileType, allOrders: this.showAllOrders, startDate, endDate })
        .then(res => {
          const fileURL = window.URL.createObjectURL(new Blob([res]))
          let fileFormat = ''

          if (fileType === 'excel') fileFormat = '.xlsx'
          if (fileType === 'csv') fileFormat = '.csv'

          const a = document.createElement('a')
          a.href = fileURL
          a.download = `orders${fileFormat}`
          a.click()
        })
    },

    onMarkAllOrders (value) {
      this.ordersList = this.ordersList.map(order => {
        if (order.fulfillmentStatus === 'Open' || order.fulfillmentStatus === 'Partially fulfilled') order.isMarked = value
        return order
      })
    },

    onFulfillOrder () {
      // take only marked orders
      const ordersPromiseArray = this.getMarkedOrders.map(order => {
        return OrdersService.fulfillOrderItem(order.id, { lineIds: order.line_items.map(line => line.id) })
      })

      // below operations are added to get all orders lines length to show the correct notification
      const orderLines = []
      this.getMarkedOrders.map(order => {
        orderLines.push(...order.line_items)
      })

      this.buttonLoading = true

      Promise.allSettled(ordersPromiseArray)
        .then(results => {
          if (results.length > 1 && results.every(result => result.status === 'rejected')) {
            this.showNotification({ type: 'error', message: 'Something went wrong. Please, try again later.' })
            this.buttonLoading = false
          } else if (results.some(result => result.status === 'rejected')) {
            const errorsArray = []

            results
              .filter((result, index) => {
                result.index = index
                return result.status === 'rejected'
              })
              .map(result => {
                const orderName = this.getMarkedOrders[result.index].name
                errorsArray.push(`Order - ${orderName}. ${result.reason.data.message}`)
              })

            this.showNotification({ type: 'error', message: errorsArray })
            this.buttonLoading = false
          } else {
            this.buttonLoading = false
            this.firstLoad()
              .then(res => {
                this.showNotification({ type: 'success', message: `${orderLines.length} order lines fulfilled` })
              })
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
